<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        :href="platformUrl()"
      >
        <platform-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ platformName() }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <store-background />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <template v-if="!isProcessFailed">
            <b-card-title class="mb-1">
              {{ $t('auth.continueRegistration.title') }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('auth.continueRegistration.subtitle') }}
            </b-card-text>

            <div
              v-if="isPending"
              class="text-center"
            >
              <b-spinner
                variant="primary"
                label="Loading ..."
              />

              <b-alert
                variant="light"
                :show="showHint"
                fade
              >
                <h4 class="alert-heading">
                  {{ $t(`auth.continueRegistration.hints.${currentHint}`) }}
                </h4>
              </b-alert>
            </div>
          </template>
          <template v-else>
            <b-alert
              v-if="errorMsg"
              v-height-fade
              variant="danger"
              show
              fade
              dismissible
            >
              <h4 class="alert-heading">
                {{ errorMsg }}
              </h4>
            </b-alert>

            <h2 class="mb-1">
              {{ $t('errors.execution_of_store_creation_failed.title') }}
            </h2>
            <p class="mb-2">
              {{ $t('errors.execution_of_store_creation_failed.text', { process: processId }) }}
            </p>
            <b-button
              variant="primary"
              class="mx-1 mb-1 btn-sm-block"
              :to="{name: 'auth-register'}"
            >{{ $t('auth.register.back_to_registration_page') }}</b-button>
          </template>

        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import PlatformLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BAlert, BButton, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  catchException, env, platformName, platformUrl, toast,
} from '@core/utils/utils'
import { ref } from 'vue'
import { heightFade } from '@core/directives/animations'
import useSession from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser, setUserData } from '@/auth/utils'
import StoreBackground from '@/views/pages/authentication/StoreBackground.vue'

const HINT_COUNT = 12
const HINT_REFRESH_DELAY = 4000

const PROCESS_CHECK_DELAY = 10000

export default {
  components: {
    BButton,
    BAlert,
    StoreBackground,
    PlatformLogo,
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BSpinner,
  },
  directives: {
    'height-fade': heightFade,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentHint: 1,
      showHint: false,
    }
  },
  computed: {
    processId() {
      return this.$route.params.process
    },
    authenticationToken() {
      return this.$route.params.token
    },
  },
  mounted() {
    useSession.csrf().then(() => {
      this.executionHandler()
      this.updateHint()
    })
  },
  created() {
  },
  methods: {
    platformUrl,
    platformName,
    env,
    updateHint() {
      this.showHint = false
      if (this.currentHint < HINT_COUNT - 1) {
        this.currentHint += 1
      } else {
        this.currentHint = 1
      }
      setTimeout(() => {
        this.showHint = true
      }, 200)

      setTimeout(() => {
        this.updateHint()
      }, HINT_REFRESH_DELAY)
    },
    executionHandler() {
      this.execute()

      if (!this.isPending || this.isProcessFailed) {
        return
      }
      setTimeout(() => {
        this.executionHandler()
      }, PROCESS_CHECK_DELAY)
    },
    execute() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true
      const payload = {
        process_id: this.processId,
        authentication_token: this.authenticationToken,
      }
      useSession.execute(payload)
        .then(response => {
          if (response.status === 200) {
            this.isPending = false
            useSession.redirectToLoginPage()
          } else if (response.status === 201) {
            this.isPending = false
            const responseData = response.data.data
            const itemData = responseData.user
            const storeData = responseData.store
            const userRole = 'admin' // itemData.role
            useSession.setToken(responseData.access_token)
            // useSession.setRefreshToken(response.data.refreshToken)
            setUserData(itemData)
            this.$store.commit('storeConfig/UPDATE_STORE_DATA', storeData)

            this.$ability.update(itemData.ability)

            // ? This is just for demo purpose as well.
            // ? Because we are showing eCommerce app's cart items count in navbar
            // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', itemData.eCommerceCartItemsCount)

            if (storeData.layout_config) {
              this.$store.commit('appConfig/UPDATE_SKIN', storeData.layout_config.skin)
              this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', Boolean(storeData.layout_config.menu_collapsed))
            }

            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            this.$router.replace({ name: 'auth-ready' })
              .then(() => {
                toast(
                  'success',
                  `Welcome ${itemData.fullName || itemData.username}`,
                  `You have successfully logged in as ${userRole}. Now you can start to managing your store!`,
                )
              })
          } else if (response.status === 202) {
            this.isPending = true
            this.isLoading = false
          } else {
            this.isLoading = false
            this.isProcessFailed = true
          }
        })
        .catch(error => {
          this.isLoading = false
          this.isProcessFailed = true

          if (error?.response?.status === 403) {
            this.$router.push({ name: 'misc-not-authorized' })
          } else if (error?.response?.status === 404) {
            this.$router.push({ name: 'error-404' })
          } else {
            this.errorMsg = error?.response?.data?.message
          }
          catchException(error)
        })
    },
  },
  setup(props, ctx) {
    const isLoading = ref(false)
    const isPending = ref(true)
    const isProcessFailed = ref(false)
    const errorMsg = ref(null)

    return {
      isLoading,
      isPending,
      isProcessFailed,
      errorMsg,
    }
  },

}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
